<template>
  <div>
    <div class="top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }" @click.native="change">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path:'/news'}">新闻报道</el-breadcrumb-item>
        <el-breadcrumb-item>中国（小谷围）人工智能创新创业大赛圆满落下帷幕</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="left">
        <h2>中国（小谷围）人工智能创新创业大赛圆满落下帷幕</h2>
        <div class="second">
          <div class="time-img"><img src="@/assets/img/time.jpg" alt=""></div>
          <span class="time">2019/1/10</span>
          <span class="time-title">人工智能创新创业大赛</span>
          <span class="gold">优胜奖
            <img src="@/assets/img/media.png" alt="">
          </span>
        </div>
        <p class="third-p1">2019年1月10日，由中国信息通信研究院、广州市番禺区人民政府主办、中国人工智能产业发展联盟、广州大学城管理委员会承办的中国（小谷围）人工智能创新创业大赛在广州大学城华南理工大学音乐厅圆满落下帷幕。自2018 年 9 月 21日大赛正式启动以来，共有200多支来自北京、广东、上海等 27 个省和直辖市的团队报名参加。最终20支出色的参赛团队突出重围来到广州总决赛比赛现场。</p>
        <div class="firth">
          <div><img src="@/assets/img/zhineng1.jpg" alt="" style="margin-bottom:1rem"></div>
        </div>
        <p class="fourth-p2">作为全球聚焦的新兴领域，人工智能不仅成为BAT等科技巨头大举进军的焦点，也成为广州这座国家重要中心城市的产业新战略之一。广州将会成为中国人工智能的创新高地、产业集群和应用示范地，成为聚集资本、人才、教育、孵化、展示与生活实验的未来“智能之城”。同时番禺区政府也设立各项创新创业和人才等利好政策，挖掘技术、发掘人才、着力促进人工智能技术和商业结合。</p>
        <div class="firth">
          <div><img src="@/assets/img/zhineng2.jpg" alt=""></div>
        </div>
        <p class="fourth-center">大赛宣传</p>
        <p class="fourth-p2">“AI+ 想象无限，未来可见”作为本次大赛的主题，以本次创新创业大赛为契机，推动国家人工智能战略快速实施。大赛注重的是AI+，强调的是AI的技术层和应用层的能力输出，利用“人工智能”技术去开创全新的应用场景，与工业、商业、金融业等行业全面融合，从而达成为用户提供更多服务的目的。赛事旨在用AI加速产业创新，与全行业深度融合，让人工智能提升全社会的创新力与生产力。</p>
        <div class="firth">
          <div><img src="@/assets/img/zhineng3.jpg" alt=""></div>
        </div>
        <p class="fourth-center">大赛致辞</p>
        <p class="fourth-p2">本届大赛紧扣新一代人工智能这一前沿科技，充分调动全社会参与人工智能的热情。大赛200多个项目覆盖“工业智能”、“智能家居”、“智能驾驶”、“数字新营销”、“未来文创”、“智慧金融”、“智慧教育”、“健康医疗”八个方面，其中来自台湾省的基于车端交通应用的驾驶策略算法模型、广州音书科技有限公司的AI助力听障人士无障碍沟通、第叁纪科技（深圳）有限公司的AI升级版量化资管交易获得了专家一致好评。经过多轮初赛、复赛，以及激烈的现场路演和答辩，最终亿可能源(上海）科技有限公司获得冠军, 深圳追一科技有限公司、第叁纪科技（深圳）有限公司获得亚军，广州探迹科技有限公司、广州中科新知科技有限公司、真微科技（广州）有限公司获得季军。中国信息通信研究院、番禺区政府等领导出席了颁奖典礼并为获奖选手和团队颁了奖。</p>
        <div class="firth">
          <div><img src="@/assets/img/zhineng4.jpg" alt=""></div>
        </div>
        <p class="fourth-center">奖杯</p>
        <p class="fourth-p2">2019年中国（小谷围）人工智能创新创业大赛将会继续进行，大赛将会继续挖掘出更多优秀人才和项目，为人才交流、技术创新、场景对接、应用孵化落地等搭建跨界协同服务的平台。届时赛题范围将更加广泛，预计参数人数和规模会再创新高！</p>
        <p style="text-align:right;margin-top:2rem;margin-bottom:0rem">原文链接：<a href="https://mp.weixin.qq.com/s/9GWk3oOeiqfzfCmUQFPfKQ">https://mp.weixin.qq.com/s/9GWk3oOeiqfzfCmUQFPfKQ</a></p>
      </div>

      <div class="right">
        <h3>相关报道</h3>
        <ul>
          <li>
            <a href="newone"><img src="@/assets/img/865.jpg" alt=""></a>
            <div class="middle">
              <a href="newone" class="h4">海内外优质创新项目齐聚汕头首届创业之星大赛大奖出炉</a>
              <span class="middle-p1">汕头.创业之星比赛</span>
              <span class="middle-p2">三等奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/1/15 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="newtwo"><img src="@/assets/img/haibao.jpg" alt=""></a>
            <div class="middle">
              <a href="newtwo" class="h4">第六届“创青春”中国青年创新创业大赛（互联网组）全国赛圆满收官</a>
              <span class="middle-p1">中国青年创新创业大赛</span>
              <span class="middle-p2">金奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/1/10 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="newseven"><img src="@/assets/img/869.jpg" alt=""></a>
            <div class="middle">
              <a href="newseven" class="h4">创新创业大赛|决赛圆满告捷，冠军花落谁家</a>
              <span class="middle-p1">青年人才创新创业大赛</span>
              <span class="middle-p2">第三名
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/3/15 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      currentRouter: state => state.nav.currentRouter
    })
  },
  methods: {
    ...mapActions({
      changeNavActive: 'nav/changeNavActive'
    }),
    change() {
      this.changeNavActive('首页')
      console.log(1)
    }
  }

}
</script>

<style lang="scss" scoped>
.fourth-center{
    text-align: center;
    margin-top: 16px;
    font-family: PingFang-SC-Bold;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #666666;
}
p{
    text-indent:2em;
}
img{
     display: block;
}
.top{
  margin-top: 65px;
  // padding: 0 205px;
  padding-left: 5%;
  width: 100%;
  height: 70px;
  background-color: #f2f2f2;
}
.el-breadcrumb {
    line-height: 70px;
}

.main{
    margin:auto;
    padding: 30px;
    width: 1420px;
    overflow: hidden;
}
.left{
    width: 60%;
    padding: 30px;
    float: left;
    overflow: hidden;
    background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(4, 0, 0, 0.15);
    border-radius: 20px;
    .second{
        overflow: hidden;
        margin-top: 40px;
    }
    .time-img{
        width: 20px;
        height: 20px;
        float: left;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .time{
        float: left;
        margin-left:10px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        color: #999999;
    }
    .time-title{
        background-color: #eff3f5;
        border-radius: 4px;
        float: left;
        font-size: 14px;
        padding: 8px 11px 8px 9px;
        line-height: 10px;
        letter-spacing: 1px;
        color: #999999;
        margin-left: 40px;
        margin-right: 20px;
    }
    .gold{
    background-color: #ffd5d5;
    float: left;
    border-radius: 4px;
	font-size: 14px;
    padding: 8px 11px 8px 9px;
    line-height: 10px;
	letter-spacing: 1px;
    color: #ff3333;
    position: relative;
    img{
        position: absolute;
        top: 0;
        left: -14px;
    }
    }
    .third-p1{
    margin-top: 60px;
    font-size: 16px;
	line-height: 30px;
	letter-spacing: 0px;
	color: #666666;
    }
    .fourth{
        padding: 0 14px;
        div img{
            width: 375px;
            height: 251px;
        }
        .fourth-img1{
            margin-right: 14px;
            float: left;
        }
    }
       .fourth-p2{
           margin-top: 16px;
            font-family: PingFang-SC-Bold;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .fourth-p3{
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .firth{
            padding: 0 14px;
            div img{
                width: 100%;
                height: 511px;
            }
        }
}
ul{
  padding: 0
}
.right{
    width: 21.5%;
    margin-left: 25px;
    float: left;
    overflow: hidden;
    h3{
    font-size: 26px;
	line-height: 48px;
	letter-spacing: 0px;
    color: #000000;
    text-align: left;
    margin-left: 30px;
    position: relative;
    }
    h3::before{
        display: block;
        content: '';
        width: 6px;
        height: 33px;
        background-color: #ff3333;
        position: absolute;
        top: 8px;
        left: -20px;
    }
    ul li{
        width: 100%;
        padding: 10px;
        background-color: #eff3f5;
        border-radius: 8px;
        margin: 15px 0;
        overflow: hidden;
        img{
            width: 100%;
            height: 230px;
            border-radius: 8px;
        }
        .middle{
          width: 100%;
          margin-left: 8px;
          overflow: hidden;
          margin-bottom: 25px;
          span{
              float: left;
              padding: 0 9px;
          }
          .middle-p1{
              background-color: #ffffff;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #999999;
          }
          .middle-p2{
              background-color: #ffd5d5;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #ff3333;
              margin-left: 25px;
              position: relative;
              img{
                  width: 20px;
                  height: 26px;
                  position: absolute;
                  top: 1px;
                  left: -15px;
              }
          }
        }
        .h4{
            display: block;
            width: 90%;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #333333;
            text-align: left;
            margin: 16px 0;
        }
        .time{
            overflow: hidden;
            margin-right: 8px;
            img{
                width: 20px;
                height: 20px;
                float: right;
            }
            .time-word{
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 1px;
                color: #999999;
                float: right;
                margin-left: 15px;
                margin-right: 5px;
            }
        }
    }
}
a:hover{
      text-decoration:none;
      color: #e45151  !important;
      transition: all 0.3s;
}
</style>
